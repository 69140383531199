'use client';

import { language } from 'i18n';
import { useTranslation } from 'i18n/client';

interface HeaderPageTitleProps {
  lng: language;
  cleanPathname: string;
}

export function HeaderPageTitle({ lng, cleanPathname }: HeaderPageTitleProps) {
  const { t } = useTranslation(lng, 'header');

  const PAGE_NAMES = {
    '/': 'home',
    '/termos/': 'terms',
    '/politica/': 'policy',
  };

  const pageName = PAGE_NAMES[cleanPathname as keyof typeof PAGE_NAMES];

  return (
    <p className="absolute right-[50%] flex translate-x-[50%] items-center justify-center px-5 py-2 text-center text-[16px] font-bold leading-[21px] text-[#0F1419] lg:hidden">
      {t(pageName)}
    </p>
  );
}
