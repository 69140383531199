import(/* webpackMode: "eager" */ "/__w/site-skeelo/site-skeelo/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/__w/site-skeelo/site-skeelo/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/__w/site-skeelo/site-skeelo/node_modules/next/font/google/target.css?{\"path\":\"src/app/[lng]/(new)/layout.tsx\",\"import\":\"Open_Sans\",\"arguments\":[{\"subsets\":[\"latin\"],\"style\":[\"normal\",\"italic\"]}],\"variableName\":\"openSans\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/__w/site-skeelo/site-skeelo/src/components/CheckBenefitFrame/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/__w/site-skeelo/site-skeelo/src/components/Clarity/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/__w/site-skeelo/site-skeelo/src/components/GoogleAnalytics/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/__w/site-skeelo/site-skeelo/src/components/GoogleTagManager/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Header"] */ "/__w/site-skeelo/site-skeelo/src/components/new/header/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/__w/site-skeelo/site-skeelo/src/components/Zendesk/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CheckBenefitProvider"] */ "/__w/site-skeelo/site-skeelo/src/hooks/useCheckBenefit.tsx");
;
import(/* webpackMode: "eager" */ "/__w/site-skeelo/site-skeelo/src/styles/new/globals.scss");
